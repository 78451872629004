import React, {Component} from 'react';
export default class Footer extends Component {


  render(){
    return(
      <footer>
        <div className="footer-container">
          <h6>Copyright Ⓒ{(new Date().getFullYear())}	Medicine Monitoring</h6>
        </div>
      </footer>
    )
  }
}
