import React from "react"
import Drug from "./Drug"

export default function DrugsList({drugs}){

  if(drugs.length === 0){
    return (
      <div className="empty-search">
        <h3>We're sorry, we can't seem to find any medications that match your search</h3>
      </div>
    )
  }
  return(
    <section className="drugslist">
      <div className="drugslist-center">
        {
          drugs.map(item => {
            return <Drug key={item.id} drug={item} />
          })
        }
      </div>
    </section>
  )
}
