import React, {Component} from "react"
//Here are the items!!!
// import items from "./data"
import Client from "./Contentful"
const DrugContext = React.createContext();

class DrugProvider extends Component{
  state={
    drugs:[],
    sortedDrugs: [],
    featuredDrugs: [],
    loading: false,
    type: "All",
    ecg: false,
    name:"",
    search:""
  };

  //getData

getData = async () => {
  try{
    let response = await Client.getEntries({
      content_type: "medicineMonitoringDrug",
      order: 'sys.createdAt'
    });
    let drugs = this.formatData(response.items)
    let featuredDrugs = drugs.filter(drug => drug.featured === true)

    this.setState({
      drugs,
      featuredDrugs,
      sortedDrugs:drugs,
      loading: false
    })

  } catch (error) {
    console.log(error)
  }
}

componentDidMount(){
  //this.getData
  // let drugs = this.formatData(items)
  // console.log(items)
  // let featuredDrugs = drugs.filter(drug => drug.featured === true)
  //
  // this.setState({
  //   drugs,
  //   featuredDrugs,
  //   sortedDrugs:drugs,
  //   loading: false
  // })

  //this.getData
  this.getData()
}

formatData(items){
  let tempItems = items.map(item => {
    let id = item.sys.id
    let images = item.fields.images.map(image => image.fields.file.url)
    let drug = {...item.fields, images, id};
    return drug;
  })
  return tempItems
}

getDrug = (slug) => {
  let tempDrugs =[...this.state.drugs]
  //filter returns an array
  //find returns an object and returns first match
  const drug = tempDrugs.find((drug) => drug.slug === slug)
  return drug
}

handleChange = event => {
  const target = event.target
  const value = target.type === "checkbox" ? target.checked : target.value
  const name = event.target.name

  console.log(name)

  this.setState({
    [name]:value
  }, this.filterDrugs)

}

filterDrugs = () => {
  let{
    drugs, type, ecg, search
  } = this.state

  //All the drugs
  let tempDrugs = [...drugs]

  //filter by type
  if(type !== "All"){
    tempDrugs = tempDrugs.filter(drug => drug.type === type)
  }

  //filter by ECG
  if(ecg){
    tempDrugs = tempDrugs.filter(drug => drug.ecg===true)
  }

  if(search !== ""){
    tempDrugs = tempDrugs.filter((drug) => {
      var name = drug.name.toLowerCase()
      var searchText = search.toLowerCase()
      var n = name.match(searchText)

      if(n != null){
        return true
      }
    })
  }

  this.setState({
    sortedDrugs:tempDrugs
  })

}

  render(){
    return(
      <DrugContext.Provider value={{
        ...this.state,
        getDrug: this.getDrug,
        handleChange: this.handleChange
      }}>
        {this.props.children}
      </DrugContext.Provider>
    )
  }
}


export function withDrugConsumer(Component){
  return function ConsumerWrapper(props){
    return (
      <DrugConsumer>
        {value => <Component{...props} context={value}/>}
      </DrugConsumer>
    )
  }
}

//you need a provider and a consumer
const DrugConsumer = DrugContext.Consumer;
export{DrugProvider, DrugConsumer, DrugContext}
