import React, {Component} from 'react';
import defaultBcg from "../images/drugbcg.jpg"
// import Hero from "../components/Hero"
import Banner from "../components/Banner"
import {Link} from "react-router-dom"
import {DrugContext} from "../context"
import StyledHero from "../components/StyledHero"

export default class SingleDrug extends Component {

  //Access URL Parameter
  constructor(props){
    super(props)
    this.state = {
      slug:this.props.match.params.slug,
      defaultBcg
    }
  }

  // componentDidMount(){
  //   // You can also access react router dom info via component did mount
  // }

  componentDidMount(){
    window.scrollTo(0, 0);
  }

static contextType = DrugContext;

  render(){
    const {getDrug} = this.context;
    const drug = getDrug(this.state.slug)

    if(!drug){
      return(
       <div className="error">
        <h3>Sorry, medication not found</h3>
        <Link to="/drugs" className="btn-primary">
          Back To Medications
        </Link>
      </div>
    )}

    const {name, type, notes, start, interim, ongoing, references, links, images} = drug

    return(
      <>
      <StyledHero img={images[0] || this.state.defaultBcg}>
        <Banner title={`${type}`}>
          <Link to="/drugs" className="btn-primary">
            Back to medications
          </Link>
        </Banner>
      </StyledHero>
        <section className="single-drug-info">
          <div className="desc">
            <h2>Medication Name</h2>
            <p>{name}</p>
          </div>
        </section>
        <section className="drug-extras">
          <h3>Before Initiation of Medication</h3>
          <ul className="extras">
            {start.map((item,index)=>{
              return <li key={index}>- {item}</li>
            })}
          </ul>
        </section>
        <section className="drug-extras">
          <h3>During Stabilisation of Medication</h3>
          <ul className="extras">
            {interim.map((item,index)=>{
              return <li key={index}>- {item}</li>
            })}
          </ul>
        </section>
        <section className="drug-extras">
          <h3>Longterm Monitoring Once Stabilised</h3>
          <ul className="extras">
            {ongoing.map((item,index)=>{
              return <li key={index}>- {item}</li>
            })}
          </ul>
        </section>
        <section className="drug-extras">
          <h3>Notes</h3>
          <p>{notes}</p>
        </section>
        <section className="drug-extras">
          <h3>References</h3>
          <ul className="extras">
            {references.map((item,index)=>{
              return <li key={index}>- {item}</li>
            })}
          </ul>
        </section>
        <section className="drug-extras">
          <h3>Reference Links</h3>
            <ul className="extras">
            {links.map((item,index)=>{
              if(item === ""){
                return <p key={index}>No reference links available for this class of medication</p>
              } else {
                return <li key={index}>- <a href={item} target="_blank" rel="noopener noreferrer">{item}</a></li>
              }
            })}
            </ul>
        </section>
      </>
    )
  }
}


/***********************************
// ... is the rest operator, to get the rest of the images

    const [mainImg,...defaultImg] = images
<div className="single-drug-images">
  {defaultImg.map((item,index) => {
    return <img key={index} src={item} alt={name} />
  })}
</div>


<section className="single-drug-info">
  <article className="desc">
    <h3>Drug Names</h3>
    <p>{name}</p>
  </article>
  <article className="info">
    <h3>Notes</h3>
    <h6>{notes}</h6>
  </article>
</section>


<h6>{ecg ? "ECG required" : ""}</h6>

***************************************/
// {links.map((item,index)=>{
//   return <li key={index}>- <a href={item}>{item}</a></li>
// })}
