import React, {Component} from 'react';
import {FaEye, FaAllergies, FaBookOpen, FaInfinity} from "react-icons/fa"
import Title from "./Title"

export default class Services extends
Component{

  state={
    services:[
      {
        icon:<FaEye/>,
        title:"Initiate & Monitor",
        info: "We provide a resource for best practices with regards to initiating and monitoring medications for your patients."
      },
      {
        icon:<FaAllergies/>,
        title:"Avoid Drug Errors",
        info: "Complaints against physicians are increasing year on year due to the increasing complexity and requirements for drug monitoring.  Let us help you stay up to date."
      },
      {
        icon:<FaBookOpen/>,
        title:"Evidence Based",
        info: "All of the medications in our library are referenced to well regarded resources such as the National Institute of Clinical Excellence (NICE) and the British National Formulary (BNF)."
      },
      {
        icon:<FaInfinity/>,
        title:"Upcoming Features",
        info: "Medicine MonitoringⒸ will be continually updated with more medications, more information and more features soon!"
      },
    ]
  }

  render(){
    return(
      <section className="services">
        <Title title="services" />
        <div className="services-center">
          {this.state.services.map((item, index) => {
            return (
              <article key={index} className="About">
              <span>{item.icon}</span>
              <h6>{item.title}</h6>
              <p>{item.info}</p>
              </article>
            )
          })}
        </div>
      </section>
    )
  }
}
