import React, {Component} from "react"
import {DrugContext} from "../context"
import Loading from "./Loading"
import Drug from "./Drug"
import Title from "./Title"

export default class FeaturedDrugs extends Component{
  static contextType = DrugContext

  render(){
    let { loading, featuredDrugs: drugs} = this.context

    drugs = drugs.map(drug => {
      return <Drug key={drug.id} drug={drug} />
    })

    return(
      <section className="featured-drugs">
        <Title title="Featured Medications This Month" />
        <div className="featured-drugs-center">
          {loading ? <Loading/>:drugs}
        </div>
      </section>
    )
  }
}
