import React from 'react';
import Hero from '../components/Hero';
import Banner from '../components/Banner';
import {Link} from "react-router-dom"
import Services from '../components/Services';
import FeaturedDrugs from '../components/FeaturedDrugs';

export default function Home () {

  return(
    <>
    <Hero>
      <Banner title="Medicine Monitoring" subtitle="Evidence Based Medication Monitoring">
        <Link to="/drugs" className="btn-primary">
          Search Medications
        </Link>
      </Banner>
    </Hero>
    <Services />
    <FeaturedDrugs />
    </>
  )
}
