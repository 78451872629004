import React from "react"
import {useContext} from "react"
import{DrugContext} from "../context"
import Title from "../components/Title"

//Get all unique values
const getUnique = (items, value) => {
  return [...new Set(items.map(item =>item[value]))]
}

export default function DrugsFilter({drugs}){

  const context = useContext(DrugContext)

  const {
    handleChange,type,ecg
  } = context


  //get unique types
  let types = getUnique(drugs, "type")
  //change to A-Z
  types = types.sort()
  //add all
  types = ["All",...types]
  //map to jsx
  types = types.map((item,index)=>{
    return <option value={item} key={index}>{item}</option>
  })
  // change to A-Z
  types = types.sort()

  return(
    <section className="filter-container">
      <Title title="Search Medications" />
      <form className="filter-form">
        {/*SEARCH AREA*/}
        <div className="form-group">
          <label htmlFor="name">Search Medication</label>
          <input type="text" name="search" autoComplete="new-password" placeholder="Search..." onChange={handleChange}/>
        </div>
        {/*END SEARCH AREA*/}
        {/*SELECT TYPE*/}
        <div className="form-group">
          <label htmlFor="type">Medication Type</label>
          <select name="type" id="type" value={type} className="form-control" onChange={handleChange}>{types}</select>
        </div>
        {/*END SELECT TYPE*/}
        {/*ECG*/}
        <div className="form-group">
          <div className="single-extra">
            <input type="checkbox" name="ecg" id="ecg" checked={ecg} onChange={handleChange}/>
            <label htmlFor="ecg">Requiring ECGs</label>
          </div>
        </div>
        {/*End of ECG*/}
      </form>
    </section>
  )
}
