import React from 'react';
import './App.css';

import Home from './pages/Home';
import Drugs from './pages/Drugs';
import SingleDrug from './pages/SingleDrug';
import Error from './pages/Error';

import{Route, Switch} from "react-router-dom"

import Navbar from './components/Navbar';
import Footer from './components/Footer';

function App() {
  return (
    <>
    <Navbar />
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/drugs/" component={Drugs} />
      <Route exact path="/drugs/:slug" component={SingleDrug} />
      <Route component={Error} />
    </Switch>
    <Footer />
    </>
  );
}

export default App;
