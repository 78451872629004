import React from "react"
import DrugsFilter from "./DrugsFilter"
import DrugsList from "./DrugsList"
import {withDrugConsumer} from "../context"
import Loading from "./Loading"

function DrugContainer({context}){
    const{loading, sortedDrugs, drugs} = context
    if(loading){
      return <Loading />;
      }
      return(
        <>          
          <DrugsFilter drugs={drugs}/>
          <DrugsList drugs={sortedDrugs}/>
        </>
        )
}


export default withDrugConsumer(DrugContainer)






// import React from "react"
// import DrugsFilter from "./DrugsFilter"
// import DrugsList from "./DrugsList"
// import {DrugConsumer} from "../context"
// import Loading from "./Loading"
//
// export default function DrugsContainer(){
//   return(
//     <DrugConsumer>
//       {
//         (value) => {
//           const {loading, sortedDrugs, drugs} = value
//
//           if(loading){
//             return(
//               <Loading />
//             )
//           }
//
//           return <div>
//             Hello from rooms container
//             <DrugsFilter drugs={drugs}/>
//             <DrugsList drugs={sortedDrugs}/>
//           </div>
//         }
//       }
//     </DrugConsumer>
//   )
// }
