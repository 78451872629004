import React from 'react';
// import{Link} from "react-router-dom"
import DrugsContainer from "../components/DrugsContainer"
// import DrugsList from "../components/DrugsList"
// import DrugsFilter from "../components/DrugsFilter"


const Drugs = () => {
  return(
    <>
    <DrugsContainer />
    </>
  )
}

export default Drugs
// <Hero hero="drugsHero">
//   <Banner title="Search Medications">
//     <Link to="/" className="btn-primary">
//       Return Home
//     </Link>
//   </Banner>
// </Hero>
