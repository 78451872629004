import React from "react"
import defaultImg from "../images/acei.png"
import propTypes from "prop-types"
import {Link} from "react-router-dom"

export default function Drug({drug}){

const{name, slug, images, type} = drug

    return(
      <article className="drug">
        <div className="img-container">
          <img src={images[0] || defaultImg} alt="chemical composition of medication" />
          <div className="type-top">
            <h6>{type}</h6>
          </div>
          <Link to={`/drugs/${slug}`} className="btn-primary drug-link">
            More Info
          </Link>
          <div className="name-container">
            <h6>{name}</h6>
          </div>
        </div>
      </article>
    )
}

Drug.propTypes ={
  drug:propTypes.shape({
    name:propTypes.string.isRequired,
    slug:propTypes.string.isRequired,
    images:propTypes.arrayOf(propTypes.string).isRequired,
    type:propTypes.string.isRequired
  })
}
